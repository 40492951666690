import erpaxa from 'app/entities/erpaxa/erpaxa.reducer';
import serviceRecord from 'app/entities/service-record/service-record.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  erpaxa,
  serviceRecord,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
