import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './service-record.reducer';

export const ServiceRecordDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const serviceRecordEntity = useAppSelector(state => state.serviceRecord.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="serviceRecordDetailsHeading">
          <Translate contentKey="oodoExcelCreatorApp.serviceRecord.detail.title">ServiceRecord</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.name">Name</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.name}</dd>
          <dt>
            <span id="requestedDate">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.requestedDate">Requested Date</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.requestedDate}</dd>
          <dt>
            <span id="createDate">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.createDate">Create Date</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.createDate}</dd>
          <dt>
            <span id="customerId">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.customerId">Customer Id</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.customerId}</dd>
          <dt>
            <span id="sequenceId">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.sequenceId">Sequence Id</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.sequenceId}</dd>
          <dt>
            <span id="membershipNum">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.membershipNum">Membership Num</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.membershipNum}</dd>
          <dt>
            <span id="memberId">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.memberId">Member Id</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.memberId}</dd>
          <dt>
            <span id="memberContactNo">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.memberContactNo">Member Contact No</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.memberContactNo}</dd>
          <dt>
            <span id="policyNo">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.policyNo">Policy No</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.policyNo}</dd>
          <dt>
            <span id="memberType">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.memberType">Member Type</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.memberType}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.type">Type</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.type}</dd>
          <dt>
            <span id="vehicleType">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.vehicleType">Vehicle Type</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.vehicleType}</dd>
          <dt>
            <span id="vehiclePlate">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.vehiclePlate">Vehicle Plate</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.vehiclePlate}</dd>
          <dt>
            <span id="productId">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.productId">Product Id</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.productId}</dd>
          <dt>
            <span id="providerId">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.providerId">Provider Id</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.providerId}</dd>
          <dt>
            <span id="driverName">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.driverName">Driver Name</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.driverName}</dd>
          <dt>
            <span id="driverNum">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.driverNum">Driver Num</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.driverNum}</dd>
          <dt>
            <span id="fromLocation">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.fromLocation">From Location</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.fromLocation}</dd>
          <dt>
            <span id="toLocation">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.toLocation">To Location</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.toLocation}</dd>
          <dt>
            <span id="state">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.state">State</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.state}</dd>
          <dt>
            <span id="isImported">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.isImported">Is Imported</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.isImported ? 'true' : 'false'}</dd>
          <dt>
            <span id="creditProformaNumber">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.creditProformaNumber">Credit Proforma Number</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.creditProformaNumber}</dd>
          <dt>
            <span id="routeRate">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.routeRate">Route Rate</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.routeRate}</dd>
          <dt>
            <span id="vendorRating">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.vendorRating">Vendor Rating</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.vendorRating}</dd>
          <dt>
            <span id="dateTimeFrom">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.dateTimeFrom">Date Time From</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.dateTimeFrom}</dd>
          <dt>
            <span id="dateTimeTo">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.dateTimeTo">Date Time To</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.dateTimeTo}</dd>
          <dt>
            <span id="comments">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.comments">Comments</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.comments}</dd>
          <dt>
            <span id="idPopulated">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.idPopulated">Id Populated</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.idPopulated ? 'true' : 'false'}</dd>
          <dt>
            <span id="dataProcessed">
              <Translate contentKey="oodoExcelCreatorApp.serviceRecord.dataProcessed">Data Processed</Translate>
            </span>
          </dt>
          <dd>{serviceRecordEntity.dataProcessed ? 'true' : 'false'}</dd>
        </dl>
        <Button tag={Link} to="/service-record" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/service-record/${serviceRecordEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ServiceRecordDetail;
