export interface IServiceRecord {
  id?: number;
  name?: string | null;
  requestedDate?: string | null;
  createDate?: string | null;
  customerId?: number | null;
  sequenceId?: number | null;
  membershipNum?: string | null;
  memberId?: number | null;
  memberContactNo?: string | null;
  policyNo?: string | null;
  memberType?: string | null;
  type?: string | null;
  vehicleType?: string | null;
  vehiclePlate?: string | null;
  productId?: number | null;
  providerId?: number | null;
  driverName?: string | null;
  driverNum?: string | null;
  fromLocation?: string | null;
  toLocation?: string | null;
  state?: string | null;
  isImported?: boolean | null;
  creditProformaNumber?: string | null;
  routeRate?: number | null;
  vendorRating?: number | null;
  dateTimeFrom?: string | null;
  dateTimeTo?: string | null;
  comments?: string | null;
  idPopulated?: boolean | null;
  dataProcessed?: boolean | null;
}

export const defaultValue: Readonly<IServiceRecord> = {
  isImported: false,
  idPopulated: false,
  dataProcessed: false,
};
