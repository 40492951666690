import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IServiceRecord } from 'app/shared/model/service-record.model';
import { getEntity, updateEntity, createEntity, reset } from './service-record.reducer';

export const ServiceRecordUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const serviceRecordEntity = useAppSelector(state => state.serviceRecord.entity);
  const loading = useAppSelector(state => state.serviceRecord.loading);
  const updating = useAppSelector(state => state.serviceRecord.updating);
  const updateSuccess = useAppSelector(state => state.serviceRecord.updateSuccess);

  const handleClose = () => {
    navigate('/service-record' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...serviceRecordEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...serviceRecordEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="oodoExcelCreatorApp.serviceRecord.home.createOrEditLabel" data-cy="ServiceRecordCreateUpdateHeading">
            <Translate contentKey="oodoExcelCreatorApp.serviceRecord.home.createOrEditLabel">Create or edit a ServiceRecord</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="service-record-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.name')}
                id="service-record-name"
                name="name"
                data-cy="name"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.requestedDate')}
                id="service-record-requestedDate"
                name="requestedDate"
                data-cy="requestedDate"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.createDate')}
                id="service-record-createDate"
                name="createDate"
                data-cy="createDate"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.customerId')}
                id="service-record-customerId"
                name="customerId"
                data-cy="customerId"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.sequenceId')}
                id="service-record-sequenceId"
                name="sequenceId"
                data-cy="sequenceId"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.membershipNum')}
                id="service-record-membershipNum"
                name="membershipNum"
                data-cy="membershipNum"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.memberId')}
                id="service-record-memberId"
                name="memberId"
                data-cy="memberId"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.memberContactNo')}
                id="service-record-memberContactNo"
                name="memberContactNo"
                data-cy="memberContactNo"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.policyNo')}
                id="service-record-policyNo"
                name="policyNo"
                data-cy="policyNo"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.memberType')}
                id="service-record-memberType"
                name="memberType"
                data-cy="memberType"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.type')}
                id="service-record-type"
                name="type"
                data-cy="type"
                type="select"
                placeholder="-- Select Payment Type --"
              >
                <option value="Cash" key="Cash">
                  Cash
                </option>
                <option value="Non Cash" key="Non Cash">
                  Non Cash
                </option>
              </ValidatedField>
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.vehicleType')}
                id="service-record-vehicleType"
                name="vehicleType"
                data-cy="vehicleType"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.vehiclePlate')}
                id="service-record-vehiclePlate"
                name="vehiclePlate"
                data-cy="vehiclePlate"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.productId')}
                id="service-record-productId"
                name="productId"
                data-cy="productId"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.providerId')}
                id="service-record-providerId"
                name="providerId"
                data-cy="providerId"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.driverName')}
                id="service-record-driverName"
                name="driverName"
                data-cy="driverName"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.driverNum')}
                id="service-record-driverNum"
                name="driverNum"
                data-cy="driverNum"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.fromLocation')}
                id="service-record-fromLocation"
                name="fromLocation"
                data-cy="fromLocation"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.toLocation')}
                id="service-record-toLocation"
                name="toLocation"
                data-cy="toLocation"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.state')}
                id="service-record-state"
                name="state"
                data-cy="state"
                type="select"
              >
                <option value="Draft" key="Draft">
                  Draft
                </option>
                <option value="Initiate" key="Initiate">
                  Initiate
                </option>
                <option value="Dispatch" key="Dispatch">
                  Dispatch
                </option>
                <option value="Start" key="Start">
                  Start
                </option>
                <option value="Reach" key="Reach">
                  Reach
                </option>
                <option value="Completed by driver" key="Completed by driver">
                  Completed by driver
                </option>
                <option value="Done" key="Done">
                  Done
                </option>
                <option value="Cancelled" key="Cancelled">
                  Cancelled
                </option>
                <option value="Changed" key="Changed">
                  Changed
                </option>
                <option value="Approved" key="Approved">
                  Approved
                </option>
                <option value="Requested" key="Requested">
                  Requested
                </option>
              </ValidatedField>
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.isImported')}
                id="service-record-isImported"
                name="isImported"
                data-cy="isImported"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.creditProformaNumber')}
                id="service-record-creditProformaNumber"
                name="creditProformaNumber"
                data-cy="creditProformaNumber"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.routeRate')}
                id="service-record-routeRate"
                name="routeRate"
                data-cy="routeRate"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.vendorRating')}
                id="service-record-vendorRating"
                name="vendorRating"
                data-cy="vendorRating"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.dateTimeFrom')}
                id="service-record-dateTimeFrom"
                name="dateTimeFrom"
                data-cy="dateTimeFrom"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.dateTimeTo')}
                id="service-record-dateTimeTo"
                name="dateTimeTo"
                data-cy="dateTimeTo"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.comments')}
                id="service-record-comments"
                name="comments"
                data-cy="comments"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.idPopulated')}
                id="service-record-idPopulated"
                name="idPopulated"
                data-cy="idPopulated"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.serviceRecord.dataProcessed')}
                id="service-record-dataProcessed"
                name="dataProcessed"
                data-cy="dataProcessed"
                check
                type="checkbox"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/service-record" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ServiceRecordUpdate;
