/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ValidatedBlobField, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { uploadDocument } from './service-record.reducer';
import ErrorTable from 'app/components/error-table';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import fields from 'app/shared/util/service-spreadsheet.fields';

export const ExcelUpload = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const loading = useAppSelector(state => state.serviceRecord.loading);
  const updateSuccess = useAppSelector(state => state.serviceRecord.updateSuccess);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessages, setErrorMessages] = useState(null);

  const handleClose = () => {
    navigate('/service-record' + location.search);
  };

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // const handleFileChange = event => {
  //   setSelectedFile(event.target.files[0]);
  //   setIsOpen(true);
  // };

  const handleFileUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      dispatch(uploadDocument({ type: 'service-record', formData }))
        .unwrap()
        .then(response => {
          if (response.status === 202 && response.data.success === false) {
            setErrorMessage(response.data.message);
            setErrorMessages(response.data.data);
          }
        })
        .catch(rejectedValueOrSerializedError => {
          // handle error here
        });
    }
  };

  const onClose = () => {
    console.log('🚀 ~ onClose ~ onClose:');
    setIsOpen(false);
  };
  const onSubmit = data => {
    console.log('🚀 ~ onSubmit ~ onSubmit:' + JSON.stringify(data?.validData));
    setIsOpen(false);
    dispatch(uploadDocument({ type: 'service-record', data }))
      .unwrap()
      .then(response => {
        if (response.status === 202 && response.data.success === false) {
          setErrorMessage(response.data.message);
          setErrorMessages(response.data.data);
        }
      })
      .catch(rejectedValueOrSerializedError => {
        // handle error here
      });
  };

  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h2 data-cy="excelUploadHeading">
          <Translate contentKey="oodoExcelCreatorApp.erpaxa.home.upload">Upload Master Data</Translate>
        </h2>
        {/* <ValidatedBlobField
          label={translate('oodoExcelCreatorApp.erpaxa.home.excelFile')}
          id="excel-file"
          name="excelFile"
          data-cy="excelFile"
          type="file"
          onChange={handleFileChange}
        /> */}
        <ReactSpreadsheetImport
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={onSubmit}
          fields={fields}
          customTheme={{
            components: {
              Button: {
                defaultProps: {
                  colorScheme: 'orange',
                },
              },
            },
          }}
        />
        <Button tag={Link} to="/service-record" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="oodoExcelCreatorApp.erpaxa.detail.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          onClick={() => {
            setIsOpen(true);
          }}
          replace
          color="primary"
          disabled={loading}
        >
          <FontAwesomeIcon icon={loading ? 'sync' : 'upload'} spin={loading} />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="oodoExcelCreatorApp.erpaxa.home.uploadButton">Upload</Translate>
          </span>
        </Button>
        <div style={{ marginTop: '30px', color: 'red' }}>{errorMessage}</div>
        {errorMessages?.length > 0 ? <ErrorTable data={errorMessages} /> : <div></div>}
      </Col>
    </Row>
  );
};

export default ExcelUpload;
