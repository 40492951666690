import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, InputGroup, FormGroup, Form, Row, Col, Table } from 'reactstrap';
import { Translate, translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { searchEntities, getEntities } from './erpaxa.reducer';
import axios from 'axios';

export const Erpaxa = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [downloadInprogress, setDownloadInProgress] = useState(false);
  const [search, setSearch] = useState('');
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const erpaxaList = useAppSelector(state => state.erpaxa.entities);
  const loading = useAppSelector(state => state.erpaxa.loading);
  const totalItems = useAppSelector(state => state.erpaxa.totalItems);

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const downloadFile = async () => {
    setDownloadInProgress(true);
    const headers = { 'Content-Type': 'blob' };
    const file = await axios.get(`api/erpaxas/download/erpaxa`, { responseType: 'arraybuffer', headers });
    const outputFilename = `ERP_AXA CL ${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([file.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setDownloadInProgress(false);
  };

  return (
    <div>
      <h2 id="erpaxa-heading" data-cy="ErpaxaHeading">
        <Translate contentKey="oodoExcelCreatorApp.erpaxa.home.title">ERP AXA CL</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2 " color="primary" tag={Link} to={`/erpaxa/upload`} disabled={loading}>
            <FontAwesomeIcon icon="upload" /> <Translate contentKey="oodoExcelCreatorApp.erpaxa.home.upload">Upload Master Data</Translate>
          </Button>
          <Button
            className="me-2"
            onClick={() => {
              downloadFile();
            }}
            color="primary"
            disabled={downloadInprogress}
          >
            <FontAwesomeIcon icon={downloadInprogress ? 'sync' : 'download'} spin={downloadInprogress} />{' '}
            <Translate contentKey="oodoExcelCreatorApp.erpaxa.home.download">Download Master Data</Translate>
          </Button>
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="oodoExcelCreatorApp.erpaxa.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/erpaxa/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="oodoExcelCreatorApp.erpaxa.home.createLabel">Create new Erpaxa</Translate>
          </Link>
        </div>
      </h2>
      <Row>
        <Col sm="12">
          <Form onSubmit={startSearching}>
            <FormGroup>
              <InputGroup>
                <Input
                  type="text"
                  name="search"
                  defaultValue={search}
                  onChange={handleSearch}
                  placeholder={translate('oodoExcelCreatorApp.erpaxa.home.search')}
                />
                <Button className="input-group-addon">
                  <FontAwesomeIcon icon="search" />
                </Button>
                <Button type="reset" className="input-group-addon" onClick={clear}>
                  <FontAwesomeIcon icon="trash" />
                </Button>
              </InputGroup>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <div className="table-responsive">
        {erpaxaList && erpaxaList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('cardType')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.cardType">Card Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('oldMembershipNumber')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.oldMembershipNumber">Old Membership Number</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('address')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.address">Address</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('emirate')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.emirate">Emirate</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('country')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.country">Country</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('mobile')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.mobile">Mobile</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('zip')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.zip">Zip</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('regionCode')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.regionCode">Region Code</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehicleType')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.vehicleType">Vehicle Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehicleModel')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.vehicleModel">Vehicle Model</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehicleMfgYear')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.vehicleMfgYear">Vehicle Mfg Year</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehiclePlate')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.vehiclePlate">Vehicle Plate</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('mailRef')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.mailRef">Mail Ref</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehicleRegCode')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.vehicleRegCode">Vehicle Reg Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehicleChasisNo')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.vehicleChasisNo">Vehicle Chasis No</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('policyNo')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.policyNo">Policy No</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehicleRegCountry')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.vehicleRegCountry">Vehicle Reg Country</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehicleEmirate')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.vehicleEmirate">Vehicle Emirate</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('deliveryDate')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.deliveryDate">Delivery Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('invoiceRefDate')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.invoiceRefDate">Invoice Ref Date</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('memberExpiryDate')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.memberExpiryDate">Member Expiry Date</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('memberActivateDate')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.memberActivateDate">Member Activate Date</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('remarks')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.remarks">Remarks</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('packageId')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.packageId">Package Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('customerCode')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.customerCode">Customer Code</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('categoryCode')}>
                  <Translate contentKey="oodoExcelCreatorApp.erpaxa.categoryCode">Category Code</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {erpaxaList.map((erpaxa, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/erpaxa/${erpaxa.id}`} color="link" size="sm">
                      {erpaxa.id}
                    </Button>
                  </td>
                  <td>{erpaxa.cardType}</td>
                  <td>{erpaxa.oldMembershipNumber}</td>
                  <td>{erpaxa.name}</td>
                  <td>{erpaxa.address}</td>
                  <td>{erpaxa.emirate}</td>
                  <td>{erpaxa.country}</td>
                  <td>{erpaxa.mobile}</td>
                  <td>{erpaxa.zip}</td>
                  <td>{erpaxa.regionCode}</td>
                  <td>{erpaxa.vehicleType}</td>
                  <td>{erpaxa.vehicleModel}</td>
                  <td>{erpaxa.vehicleMfgYear}</td>
                  <td>{erpaxa.vehiclePlate}</td>
                  <td>{erpaxa.mailRef}</td>
                  <td>{erpaxa.vehicleRegCode}</td>
                  <td>{erpaxa.vehicleChasisNo}</td>
                  <td>{erpaxa.policyNo}</td>
                  <td>{erpaxa.vehicleRegCountry}</td>
                  <td>{erpaxa.vehicleEmirate}</td>
                  <td>{erpaxa.deliveryDate}</td>
                  <td>{erpaxa.invoiceRefDate}</td>
                  <td>{erpaxa.memberExpiryDate}</td>
                  <td>{erpaxa.memberActivateDate}</td>
                  <td>{erpaxa.remarks}</td>
                  <td>{erpaxa.packageId}</td>
                  <td>{erpaxa.customerCode}</td>
                  <td>{erpaxa.categoryCode}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/erpaxa/${erpaxa.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/erpaxa/${erpaxa.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/erpaxa/${erpaxa.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.home.notFound">No Erpaxas found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={erpaxaList && erpaxaList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Erpaxa;
