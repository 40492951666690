import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ServiceRecord from './service-record';
import ServiceRecordDetail from './service-record-detail';
import ServiceRecordUpdate from './service-record-update';
import ServiceRecordDeleteDialog from './service-record-delete-dialog';
import ExcelUpload from './excel-upload';

const ServiceRecordRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ServiceRecord />} />
    <Route path="new" element={<ServiceRecordUpdate />} />
    <Route path="upload" element={<ExcelUpload />} />
    <Route path=":id">
      <Route index element={<ServiceRecordDetail />} />
      <Route path="edit" element={<ServiceRecordUpdate />} />
      <Route path="delete" element={<ServiceRecordDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ServiceRecordRoutes;
